:root { 
--blue_gray_200:#b2bec3; 
--gray_500:#9b9b9b; 
--blue_A700:#0063f7; 
--blue_gray_600:#636e72; 
--gray_800:#3f3f3f; 
--amber_200:#ffe393; 
--teal_A100:#98ffe0; 
--gray_200:#e9e9e9; 
--gray_100:#f0f3f5; 
--black_900_d8:#1a0710d8; 
--black_900:#000000; 
--blue_gray_700:#42505c; 
--cyan_50:#ebfafa; 
--blue_gray_900:#2d3436; 
--deep_purple_A200:#7e57ff; 
--gray_800_b3:#3f3f3fb3; 
--white_A700:#ffffff; 
--black_900_05:#00000005; 
}